import background from '../images/background.jpg'
import cardBack from '../images/CardBack.jpg'

import cardCityHall from '../images/CardCityHall.jpg'
import cardConvoy from '../images/CardConvoy.jpg'
import cardHarbor from '../images/CardHarbor.jpg'
import cardMarket from '../images/CardMarket.jpg'
import cardPalace from '../images/CardPalace.jpg'
import cardTavern from '../images/CardTavern.jpg'
import cardTreasure from '../images/CardTreasure.jpg'

import coin1 from '../images/Coin1.png'
import coin2 from '../images/Coin2.png'
import coin5 from '../images/Coin5.png'

import headStartToken from '../images/coupdavance.png'

import dice2 from '../images/Dice_2.jpg'
import dice3 from '../images/Dice_3.jpg'
import dice4 from '../images/Dice_4.jpg'

import districtCityHall1 from '../images/DistrictCityHall1.jpg'
import districtCityHall2 from '../images/DistrictCityHall2.jpg'
import districtConvoy1 from '../images/DistrictConvoy1.jpg'
import districtConvoy2 from '../images/DistrictConvoy2.jpg'
import districtHarbor from '../images/DistrictHarbor.jpg'
import districtJail from '../images/DistrictJail.jpg'
import districtMarket from '../images/DistrictMarket.jpg'
import districtPalace1 from '../images/DistrictPalace1.jpg'
import districtPalace2 from '../images/DistrictPalace2.jpg'
import districtTavern from '../images/DistrictTavern.jpg'
import districtTreasure from '../images/DistrictTreasure.jpg'

import iconCityHall from '../images/IconCityHall.png'
import iconConvoy from '../images/IconConvoy.png'
import iconHarbor from '../images/IconHarbor.png'
import iconMarket from '../images/IconMarket.png'
import iconPalace from '../images/IconPalace.png'
import iconTavern from '../images/IconTavern.png'
import iconTreasure from '../images/IconTreasure.png'
import mercenary from '../images/Mercenary.png'

import partnerBlue from '../images/PartnerBlue.png'
import partnerGreen from '../images/PartnerGreen.png'
import partnerPurple from '../images/PartnerPurple.png'
import partnerRed from '../images/PartnerRed.png'
import partnerYellow from '../images/PartnerYellow.png'
import patrol from '../images/Patrol.png'

import princePanel from '../images/PrincePanel.png'
import sealBlue from '../images/SealBlue.png'
import sealGreen from '../images/SealGreen.png'
import sealPurple from '../images/SealPurple.png'
import sealRed from '../images/SealRed.png'
import sealYellow from '../images/SealYellow.png'

import tokenKickBlue from '../images/TokenKickBlue.png'
import tokenKickGreen from '../images/TokenKickGreen.png'
import tokenKickPurple from '../images/TokenKickPurple.png'
import tokenKickRed from '../images/TokenKickRed.png'
import tokenKickYellow from '../images/TokenKickYellow.png'

import tokenMoveBlue from '../images/TokenMoveBlue.png'
import tokenMoveGreen from '../images/TokenMoveGreen.png'
import tokenMovePurple from '../images/TokenMovePurple.png'
import tokenMoveRed from '../images/TokenMoveRed.png'
import tokenMoveYellow from '../images/TokenMoveYellow.png'

import tokenStealBlue from '../images/TokenStealBlue.png'
import tokenStealGreen from '../images/TokenStealGreen.png'
import tokenStealPurple from '../images/TokenStealPurple.png'
import tokenStealRed from '../images/TokenStealRed.png'
import tokenStealYellow from '../images/TokenStealYellow.png'
import victoryToken from '../images/VictoryToken.jpg'

import weekCardBack from '../images/WeekCardBack.jpg'
import weekCardCityHall1 from '../images/WeekCardCityHall1.jpg'
import weekCardCityHall2 from '../images/WeekCardCityHall2.jpg'
import weekCardConvoy1 from '../images/WeekCardConvoy1.jpg'
import weekCardConvoy2 from '../images/WeekCardConvoy2.jpg'
import weekCardHarbor1 from '../images/WeekCardHarbor1.jpg'
import weekCardHarbor2 from '../images/WeekCardHarbor2.jpg'
import weekCardMarket1 from '../images/WeekCardMarket1.jpg'
import weekCardMarket2 from '../images/WeekCardMarket2.jpg'
import weekCardPalace1 from '../images/WeekCardPalace1.jpg'
import weekCardPalace2 from '../images/WeekCardPalace2.jpg'
import weekCardTavern1 from '../images/WeekCardTavern1.jpg'
import weekCardTavern2 from '../images/WeekCardTavern2.jpg'

const Images = {
    cardBack, sealBlue, sealGreen, sealPurple, sealRed, sealYellow,
    cardCityHall, cardHarbor, cardMarket, cardPalace, cardTavern, cardTreasure, cardConvoy,
    iconCityHall, iconHarbor, iconMarket, iconPalace, iconTavern, iconTreasure, iconConvoy,
    districtCityHall1, districtCityHall2, districtHarbor, districtMarket, districtPalace1, districtPalace2, districtTavern, districtTreasure, districtJail, districtConvoy1, districtConvoy2, // ADD CONVOY TILE HERE
    princePanel,
    patrol, mercenary, victoryToken,
    coin1,coin2,coin5,
    dice2, dice3, dice4,
    partnerBlue, partnerGreen, partnerPurple, partnerRed, partnerYellow,
    tokenKickBlue, tokenKickGreen, tokenKickPurple, tokenKickRed, tokenKickYellow,
    tokenMoveBlue, tokenMoveGreen, tokenMovePurple, tokenMoveRed, tokenMoveYellow,
    tokenStealBlue, tokenStealGreen, tokenStealPurple, tokenStealRed, tokenStealYellow,
    weekCardBack,
    weekCardCityHall1, weekCardCityHall2, weekCardHarbor1, weekCardHarbor2, weekCardMarket1, weekCardMarket2, weekCardPalace1, weekCardPalace2, weekCardTavern1, weekCardTavern2, weekCardConvoy1, weekCardConvoy2,
    headStartToken,
    background
}

export default Images